<template>
    <div class="max-width">
      <div class="wrapper">
        <div class="bk-title bk-font36 content-title">也在网</div>
        <div class="content-summary">
          也在网隶属于佛山市蝶与花信息技术有限公司。主营：婚恋服务，活动策划。
        </div>
        <div class="title-1">
          <!-- 题目 -->
          <div class="bk-flex"><span>网站简介</span></div>
          <!-- 分割线 -->
          <div class="bk-border-1"></div>
          <!-- 内容 -->
          <div class="conent bk-conent-1">
            <div class="conent-right">
              也在网是由专业红娘提供服务的婚恋相亲网站，采用红娘+网络的婚配服务模式，全程为会员提供完善的征婚服务。也在网红娘均经过专业的婚恋学，心理学培训，结合五年多的征婚服务经验，提供专业个性化的红娘服务。
            </div>
          </div>
        </div>
  
        <div class="title-1">
          <!-- 题目 -->
          <div class="bk-flex"><span>LOGO释义</span></div>
          <!-- 分割线 -->
          <div class="bk-border-1"></div>
          <!-- 内容 -->
          <div class="conent bk-conent-1">
            <div class="conent-right">
              该标识图片创意来源于蝴与花的结合。蝴蝶的双宿双飞，花朵集浪漫与美于一身，是传统的爱情和合美感情的象征。
            </div>
          </div>
        </div>

        <div class="title-1">
          <!-- 题目 -->
          <div class="bk-flex"><span>发展历史</span></div>
          <!-- 分割线 -->
          <div class="bk-border-1"></div>
          <!-- 内容 -->
          <ul class="bk-ul-2">
            <li>
              2016年3月，也在网的前身佛山市红也婚姻服务有限公司在广东佛山顺德成立。
            </li>
            <li>
              2016年7月，与企业首场精品相亲会推出，受到广大单身男女的一致好评。
            </li>
            <li>2016年8月，正式开通线下1对1人工红娘服务。</li>
            <li>2018年12月底，完成佛山区域线下100场精品相亲会。</li>
            <li>2019年4月，佛山禅城分部成立。</li>
            <li>
              2019年9月，着手西安技术部门的搭建，自主开发线上pc端网站、移动端网站、小程序、安卓端app、苹果端app的开发，以及内部crm系统的开发搭建。
            </li>
            <li>2020年4月，内部客户管理系统上线。</li>
            <li>2021年9月，pc端网站、移动端网站、小程序上线。</li>
            <li>2021年12月，苹果端app、安卓端app上线。</li>
          </ul>
        </div>
  
        <div class="title-1">
          <!-- 题目 -->
          <div class="bk-flex"><span>网站服务</span></div>
          <!-- 分割线 -->
          <div class="bk-border-1"></div>
          <!-- 内容 -->
          <ul class="bk-ul-3">
            <li class="bk-strong">网站特色</li>
            <li>
              也在网是一个相亲网站，相亲网站与交友网站有很大的差别。相亲网站帮助会员通过网站提供的资料信息，找到心仪对象，并通过专业红娘用电话全程为会员提供与心仪对象牵线相亲安排等人工服务。
            </li>
            <li class="bk-strong">网络平台与电话红娘相结合的服务模式</li>
            <li>
              也在网结合网络平台加电话红娘的服务模式，这种模式聚焦于两个最能够帮助都市白领寻找到终身伴侣的关键要素：高约会成功率和高时间效率。网络平台让会员能在众多的优质会员中迅速寻找到心仪的对象，电话红娘让会员能高效地与心仪对象成功匹配交往
            </li>
            <li class="bk-strong">专业红娘团队提供服务</li>
            <li>
              也在网上拥有庞大的专业红娘团队，每天为成千上万的会员提供电话牵线匹配，会员在注册数个小时内便可开始享受到来自也在红娘的贴心服务。
            </li>
            <li class="bk-strong">会员真实身份验证</li>
            <li>
              也在网根据身份证认证以确保会员的身份的真实性，有效的杜绝了开放通讯给会员隐私带来的隐患。
            </li>
            <li class="bk-strong">
              人工红娘审核把关
            </li>
            <li >
              诚信体系建立，会员注册完成后，通过完成资料填写，照片上传，证件认证等方式可以提高自己的诚信值，诚信值越高，会员资料真实性，可靠性越高
            </li>
          </ul>
        </div>
  
        <div class="title-1 content-bottom">
          <!-- 题目 -->
          <div class="bk-flex"><span>相亲活动</span></div>
          <!-- 分割线 -->
          <div class="bk-border-1"></div>
          <!-- 内容 -->
          <ul class="bk-ul-4">
            <li class="bk-li-strong">名企名校亲会</li>
            <li>
              主要针对工作繁忙的80、90、00后单身男女，每次组织200名左右单身人士，由婚恋专家或国家心理咨询师主持，每场根据不同的参与者精心制定活动规则和互动环节。
            </li>
            <li class="bk-li-strong">长辈相亲团</li>
            <li>
              “精品长辈相亲团”是专为单身男女的父母攀亲而设立的，父母直接参与，帮助单身人士一起找对象。
            </li>
         
            <li class="bk-li-strong">万人相亲活动</li>
            <li>主要针对大型企业、党政机关的单身男女而举办的活动。</li>
            <li class="bk-li-strong">云相亲服务</li>
            <li>
              一种新型相亲方式，通过1v1在线面对面相亲交友，将相亲与直播的属性相结合。
            </li>
            <li>
              除了以上活动，也在还通过组织户外旅游、婚恋课堂、假面舞会等创意活动帮助单身男女解决终身幸福问题。
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    metaInfo: {
      title: "也在网-婚恋情缘平台简介一览",
      meta: [
        { charset: "utf-8" },
        {
          name: "viewport",
          content:
            "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
        },
      ],
    },
  };
  </script>
  
  <style lang="less" scoped>
  .max-width {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wrapper {
    max-width: 1200px;
    font-size: 15px;
    color: #222;
    /**
    1.标题
    */
    .bk-title {
      font-family: PingFangSC, Avenir, Helvetica, Arial, sans-serif;
      font-weight: 600;
    }
    .bk-font36 {
      font-size: 36px;
      line-height: 48px;
    }
    .content-title {
      margin: 26px 0 7px 0;
      padding-right: 68px;
      position: relative;
    }
    /*
  
    */
    .content-summary {
      margin-top: 23px;
      margin-bottom: 25px;
    }
    .content-bottom {
      padding-bottom: 50px;
    }
  
    .title-1 {
      .bk-flex {
        font-size: 24px;
        font-weight: 600;
        line-height: 27px;
        margin-top: 36px;
        margin-bottom: 16px;
        border-left: 5px solid #ff5e5e;
  
        span {
          margin-left: 9px;
        }
      }
      .bk-border-1 {
        height: 1px;
        width: 100%;
        background: #e8e8e8;
        margin-bottom: 14px;
      }
      .sbsq{
        display: flex;
        justify-content: center;
      }
      .conent {
        display: flex;
        //   flex-wrap: wrap;
      }
      //列表01
      .bk-ul-1 {
        li {
          margin-bottom: 14px;
        }
      }
      .bk-ul-2 {
        position: relative;
        // padding-bottom: 100px;
        li {
          margin-bottom: 14px;
        }
        .ul-image-right {
          position: absolute;
          bottom: 0;
          right: 0;
          .imageso {
            border: 1px solid rgba(34, 34, 34, 0.06);
            display: flex;
            justify-content: center;
            img {
              display: block;
              max-width: 204px;
              max-height: 272px;
              min-width: 96px;
              min-height: 96px;
  
              border-radius: 4px 4px 0 0;
              border-bottom: none;
            }
          }
  
          .image-description {
            padding-left: 8px;
            line-height: 30px;
            border: 1px solid #f5f5f5;
            border-radius: 0 0 4px 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #fafafa;
            font-size: 12px;
            color: #999;
          }
        }
      }
      .bk-ul-3 {
        position: relative;
        // padding-bottom: 180px;
        li {
          margin-bottom: 14px;
        }
        .bk-strong {
          font-weight: 700;
        }
        .ul-image-right {
          position: absolute;
          bottom: 0;
          right: 0;
          .imageso {
            border: 1px solid rgba(34, 34, 34, 0.06);
            display: flex;
            justify-content: center;
            img {
              display: block;
              max-width: 204px;
              max-height: 272px;
              min-width: 96px;
              min-height: 96px;
  
              border-radius: 4px 4px 0 0;
              border-bottom: none;
            }
          }
  
          .image-description {
            padding-left: 8px;
            line-height: 30px;
            border: 1px solid #f5f5f5;
            border-radius: 0 0 4px 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #fafafa;
            font-size: 12px;
            color: #999;
          }
        }
      }
      .bk-ul-4 {
        position: relative;
      //   padding-bottom: 180px;
        li {
          margin-bottom: 14px;
        }
        .bk-li-strong {
          font-weight: 600;
        }
        .bk-li-image-right-1 {
          .imageso {
            border: 1px solid rgba(34, 34, 34, 0.06);
            display: flex;
            height: 400px;
            img {
              display: block;
              width: 598px;
              border-radius: 4px 4px 0 0;
              border-bottom: none;
            }
          }
  
          .image-description {
            padding-left: 8px;
            line-height: 30px;
            border: 1px solid #f5f5f5;
            border-radius: 0 0 4px 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #fafafa;
            font-size: 12px;
            color: #999;
          }
        }
      }
      .bk-conent-1 {
        .conent-right {
          display: flex;
          // align-items: center;
        }
        .conent-right-1 {
          margin-right: 10px;
          text-align: justify;
          .conent-right-1-bottom {
            margin-top: 14px;
          }
        }
  
        .image-right {
          .imageso {
            border: 1px solid rgba(34, 34, 34, 0.06);
            display: flex;
            justify-content: center;
            img {
              display: block;
              max-width: 204px;
              max-height: 272px;
              min-width: 96px;
              min-height: 96px;
  
              border-radius: 4px 4px 0 0;
              border-bottom: none;
            }
          }
  
          .image-description {
            padding-left: 8px;
            line-height: 30px;
            border: 1px solid #f5f5f5;
            border-radius: 0 0 4px 4px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            background-color: #fafafa;
            font-size: 12px;
            color: #999;
          }
        }
      }
    }
  }
  </style>